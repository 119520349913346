import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { MqttService } from "ngx-mqtt";
import { FormControl } from '@angular/forms';
import {
    UnderfrequencyPayload,
    UnderfrequencyEventService
} from '../../services/underfrequency-event.service';
import { EventManagementService } from "../../services/event-management.service";
import { SharedService } from "../../services/shared.service";
import * as _ from 'lodash';

@Component({
    selector: 'app-underfrequency-list',
    templateUrl: './underfrequency-list.component.pug',
    styleUrls: ['./underfrequency-list.component.scss']
})
export class UnderfrequencyListComponent implements OnInit {
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private listService: UnderfrequencyEventService,
        private toastr: ToastrService,
        private emService: EventManagementService,
        private enutsMqttService: MqttService,
        private sharedService: SharedService,
    ) {}

    loading = false;
    interval: any;
    setPointTimeout;
    unsubscribe: Subject<boolean> = new Subject<boolean>();
    eventNodeIds: string[] = [];
    allActiveEvents: UnderfrequencyPayload[];
    activeEvents: UnderfrequencyPayload[];
    excludeActiveFields = ['disarm_reason'];

    allPendingRegistrations: UnderfrequencyPayload[];
    pendingRegistrations: UnderfrequencyPayload[];
    excludePendingFields = ['last_updated_dttm', 'event_details_link'];
    thingIdToRegIdMap = {};
    cachedSetpoints = {};

    availablePrograms;
    programs = [];
    filteredPrograms = [];
    programFilter = '';

    ngOnInit() {
        this.interval = setInterval(() => this.fetchWithRetries(), 60 * 1000);
        this.fetchPrograms();
    }

    fetchPrograms() {
        this.listService.getProgramList().then((programs) => {
            const pids = (this.route.snapshot.queryParams.program_ids || '').split(',').filter(p => Boolean(p));
            this.programs = programs.filter(p => pids.includes(p.program_id));
            this.filteredPrograms = programs;
            this.availablePrograms = programs;
            this.onUpdatePrograms();
        });
    }

    filterPrograms() {
        const pids = new Set(this.programs.map(p => p.program_id));
        const toFind = this.programFilter.toLowerCase();
        this.filteredPrograms = this.availablePrograms
            .filter(p => !pids.has(p.program_id))
            .filter(p => `${p.operator_display_label} - ${p.program_display_label}`.toLowerCase().includes(toFind));
    }

    selectProgram(ev) {
        this.programs.push(ev.option.value);
        this.onUpdatePrograms();
    }

    removeProgram(program) {
        this.programs = this.programs.filter(p => p.program_id !== program.program_id);
        this.onUpdatePrograms();
    }

    onUpdatePrograms() {
        setTimeout(() => {
            this.programFilter = '';
            this.filterPrograms();
        }, 5);

        const current = this.route.snapshot.queryParams.program_ids || '';
        if (current === this.programs.map(p => p.program_id).join(',')) {
            return;
        }
        const queryParams: any = {};
        if (this.programs.length) {
            queryParams.program_ids = this.programs.map(p => p.program_id).join(',');
        }
        this.router.navigate(['underfrequency-management'], {replaceUrl: true, queryParams});
        if (!this.allActiveEvents || !this.allPendingRegistrations) {
            this.fetchWithRetries();
        } else {
            this.filterRows();
        }
    }

    fetchWithRetries(retries=0) {
        // Don't re-initiate if we're already loading
        if (this.loading) return;

        // EMS returns some intermittent 4xx/5xxs, so keep trying
        this.loading = true;
        this.fetchData()
            .catch((err) => {
                if (err && err.statusCode > 400 && retries < 3) {
                    const incremented = retries + 1;
                    setTimeout(() => this.fetchWithRetries(incremented), 1000 * incremented);
                } else {
                    console.log(err);
                    this.toastr.error('Encountered an error fetching registration sets from EMS');
                    this.loading = false;
                }
            });
    }

    fetchData(): Promise<void> {
        if (this.programs.length === 0) {
            this.loading = false;
            return Promise.resolve();
        }

        // We fetch all the events and then filter them. Later look into fetching by program id
        const active = this.listService.getList()
            .then((data) => this.allActiveEvents = data);

        return this.listService.getPending({}).then((data) => {
            // Gotta wait for the active call to finish if it hasn't already
            active.then(() => {
                // Now that we have event nodes
                this.subscribeToMQTT();

                // Then filter out any pending registration sets that are already active
                this.allPendingRegistrations = data
                    .filter((row) => (
                        !this.allActiveEvents.find((activeRow) => activeRow.registration_set_id === row.registration_set_id)
                    ));
              const all = [...this.allActiveEvents, ...this.allPendingRegistrations]
              all.forEach((payload) => {
                if(this.cachedSetpoints[payload.registration_id]) {
                  payload.frequency_setpoint = this.cachedSetpoints[payload.registration_id];
                }
              });
              this.filterRows();
              this.getQueryId();
              this.loading = false;
            });
        });
    }

    filterRows() {
        const pids = new Set(this.programs.map(p => p.program_id));
        this.pendingRegistrations = this.allPendingRegistrations.filter(r => pids.has(r.program_id));
        this.activeEvents = this.allActiveEvents.filter(e => pids.has(e.program_id));
    }

    subscribeToMQTT() {
        const allIds = this.allActiveEvents
            .map(e => e.event_id)
            .filter(Boolean);
        allIds.push('new_event');

        // Don't re-subscribe to the same event list
        if (allIds.join(',') === this.eventNodeIds.join(',')) {
            return;
        }

        // Unsubscribe from any existing subscriptions.
        this.unsubscribe.next(true);

        setTimeout(() => {
            // Set the event node ids for next time, and subscribe to the next topic
            this.eventNodeIds = allIds;
            this.sharedService.subscribeToTopic(
                null,
                allIds,
                "event",
                this.enutsMqttService,
                msg => this.fetchWithRetries(),
                false,
                null,
                this.unsubscribe,
                this.emService,
            );
        }, 100);
    }

    getQueryId() {
      const regSetIds = [];
      const all = [...this.allActiveEvents, ...this.allPendingRegistrations]
      all.forEach((payload) => {
        if(payload.registration_id) regSetIds.push(payload.registration_id)
      })
      if(regSetIds.length) {
        this.emService.post('/v1/frequency_setpoint/get_query_id', {ids: regSetIds}, {}).pipe().subscribe(
          (resp)=>{
            if(resp.data.map)
              this.thingIdToRegIdMap = {...this.thingIdToRegIdMap, ...resp.data.map}
            this.getFrequencySetPoints(resp.data.queryId);
          },
          (err)=>{
            console.log(err)
          }
        )
      }
    }

    getFrequencySetPoints(queryId) {
      this.emService.get('/v1/frequency_setpoint/' + queryId, {}).pipe().subscribe(
        (resp)=>{
          if(resp.data.status =='SUCCEEDED'){
            this.populateFrequencySetPoints(resp.data);
          } else if(resp.status !== 'FAILED') {
            this.setPointTimeout = setTimeout(()=>{
              this.getFrequencySetPoints(queryId)
            }, 2000)
          }
        },
        (err)=>{
          clearTimeout(this.setPointTimeout);
          console.log(err)
        }
      )
    }

    populateFrequencySetPoints(data) {
      const controller = this;
      const all = [...this.allActiveEvents, ...this.allPendingRegistrations];

      data.resp.forEach((d)=>{
        d.registration_id = this.thingIdToRegIdMap[d.id]
      })
      const fspMap =  _.keyBy(data.resp, 'registration_id');

      all.forEach((o)=>{
        if(fspMap[o.registration_id]) {
          o.frequency_setpoint = fspMap[o.registration_id].value + " Hz"
          controller.cachedSetpoints[o.registration_id] = o.frequency_setpoint;
        }
        }
      )
    }

    ngOnDestroy() {
        this.unsubscribe.next(true);
        clearInterval(this.interval);
        clearTimeout(this.setPointTimeout);
    }
}
