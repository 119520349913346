import { Injectable } from '@angular/core';
import { EventManagementService } from './event-management.service';
import { UserService } from './user.service';
import * as _ from 'lodash';
import { get, uniq } from 'lodash';
import { EventNode } from "../classes/event_node";
import { ToastrService } from 'ngx-toastr';
import { SharedService } from './shared.service';


const ALLOWED_PORTFOLIO_OBLIGATION_SOURCES = [
    'SUM_CUSTOMER_OFFER',
];

export enum DEVICE_STATUS {
    ARMING = 'Arming',
    ARM_ERROR = 'Arm Error',
    ARMED = 'Armed',
    TRIPPING = 'Tripping',
    TRIP_ERROR = 'Trip Error',
    TRIPPED = 'Tripped',
    DISARMING = 'Disarming',
    DISARM_ERROR = 'Disarm Error',
    DISARMED = 'Disarmed',
    LOCKED_OUT = 'Locked Out',
    DR_TRIPPED = 'In a DR Event',
    UNKNOWN = 'Unknown',
}

export enum ACTION {
    ARM = 'Arm',
    GREYED_ARM = 'Arm|Disabled',
    GREYED_OPT_IN = 'Opt In|Disabled',
    DISARM = 'Disarm',
    EMERGENCY_DISARM = 'Emergency Exit',
    MANAGE_EXCEPTION = 'Manage Exception',
    OPT_IN = 'Opt In',
}

export enum DISARM_REASON {
    MANUAL_DISARM = 'Manual Disarm',
    CUSTOMER_OPT_OUT = 'Customer Opted Out',
    NO_CUSTOMER_OFFER = 'No Customer Offer',
    LOCAL_LOCKOUT = 'Device is locally locked out',
    DR_TRIPPED = 'In a Non-UFR Event',
    NO_AVAILABILITY = 'No availability',
    READY_TO_ARM = 'Ready to Arm',
}

export interface UnderfrequencyPayload {
    program_display_label: string;
    portfolio_display_label: string;
    registration_set_display_label: string;
    site_display_label: string;
    organization_name: string;
    registration_set_id: string;
    registration_id: string;
    site_id: string;
    organization_id: string;
    flexible_asset_id: string;
    portfolio_id: string;
    program_id: string;
    product_id: string;
    product: any; // For the UOM config
    actual_frequency: string; // toFixed
    frequency_setpoint: string;

    // Interpreted fields where we set values based on the reg-set level state
    device_status: DEVICE_STATUS;
    action: ACTION;
    disarm_reason?: DISARM_REASON | string; // Pending-only, could come from zero_reason too

    // BASIC data fetched through EMS
    availability: number;
    availability_zero_reason: string[];
    availability_zero_reason_display_label: string;
    locked_out: boolean;
    offer: number;
    load: number;

    // Active-node only fields
    last_updated_dttm?: string;
    event_id?: string;
    event_node_id?: string;
    workflow_status?: string;
}

export interface UnderfrequencyProgram {
    operator_id: string;
    operator_display_label: string;
    program_id: string;
    program_display_label: string;
}

export class UnderfrequencyServiceError extends Error {}

@Injectable({
    providedIn: 'root'
})
export class UnderfrequencyEventService {
    constructor(
        private EMS: EventManagementService,
        private userService: UserService,
        private toastr: ToastrService,
        private SS: SharedService
    ) {}

    async getProgramList(): Promise<UnderfrequencyProgram[]> {
        // Get program display labels
        const response = await this.EMS.get('/v1/underfrequency_hierarchy', {}).toPromise();
        return response.data;
    }

    /*
     * This method assembles the registration set level data out of a bunch of
     * EMS calls: `/events` to get the active events (and reg sets), event nodes
     * to get the workflow_state level data, BASIC, and `/portfolios` to get
     * the portfolio names
     */
    async getList(query = {}): Promise<UnderfrequencyPayload[]> {
        // Get events from EMS
        const qs = {
            event_type: 'OFFICIAL_UFR',
            hierarchy: false,
            ...query,
        };
        const response = await this.EMS.get('/v1/events', query).toPromise();

        if (response.data.length === 0) return [];

        // Now to get the registration set id/name and workflow status, we call for active event nodes
        const event_ids = response.data.map((ev) => ev.event_id);

        const promises = event_ids.map(async id => {
          const resp = await this.EMS.post(`/v1/events/event_nodes`, { 'event_ids':[id] }, {}).toPromise()
            .catch(
              (error)=>{
                this.toastr.error('Error getting event nodes for event id: ' + id, '', {
                  disableTimeOut: true,
                  closeButton: true
                });
                console.log(error)
              }
            );
            return resp?.data;
        })

        const nodeResponse = await Promise.all(promises).then((value) =>{
          var arr = [];
          value.forEach((val:any[])=>{
            if(val && val.length){
              arr.push(val[0])
            }
          })
          return arr
          }
        );

        // Now go fetch the registration set details from BASIC & Atlas
        const registration_set_ids = uniq(nodeResponse.reduce((ids, ev) => {
            return ev['activeNodes']
                .filter(n => n.registration_set_id)
                .map(n => n.registration_set_id)
                .concat(ids);
        }, []));
        const regSetDetailsResp = await this.getRegistrationSetDetails(registration_set_ids);
        const locale = this.userService.user?.default_locale;

        // Do the database join in UI code
        const joined = response.data.map((ev) => {
            // UFR events _should_ only have one event node, so we raise that state
            // up to the event level - get the registration name and munge
            // the workflow status to get the "device status"
            const enutsNode = get(nodeResponse.find((evnodes:EventNode) => evnodes.event_id === ev.event_id), 'activeNodes.0');
            if (!enutsNode) return;

            const regSetDetails = regSetDetailsResp[enutsNode?.registration_set_id] || {};
            const [device_status, action] = this.workflowStatusToDeviceStatus(enutsNode, regSetDetails);
            const labels = enutsNode?.registration_set_display_labels || {};

            return {
                registration_set_display_label: this.SS.flattenDisplayLabel(labels),
                registration_set_id: enutsNode?.registration_set_id,
                last_updated_dttm: enutsNode?.last_updated_dttm,
                device_status,
                action,
                event_id: ev.event_id,
                event_node_id: enutsNode?.event_node_id,
                workflow_status: enutsNode?.workflow_status,
                ...regSetDetails
            };
        }).filter(Boolean);
        return _.orderBy(joined, ['registration_set_id'], ['desc']);
    }

    async getPending(query): Promise<UnderfrequencyPayload[]> {
        // Get the underfrequency registration sets from Atlas via EMS and get them into a sensible array
        const qs = {
            fill_out_registration_sets: 'true',
            ...query,
        };
        const response = await this.EMS.get('/v1/underfrequency_registration_sets', qs).toPromise();
        const data = Object.keys(response.data).reduce((res, program) => {
            return res.concat(Object.values(response.data[program]))
        }, []);
        if (data.length === 0) return [];

        // For each reg-set, go fetch BASIC/Atlas data
        const registration_set_ids = uniq(data.map((ev) => ev.id));
        const regSetDetailsResp = await this.getRegistrationSetDetails(registration_set_ids);
        const locale = this.userService.user?.default_locale;

        const joined = data.map((ev) => {
            const regSetDetails = regSetDetailsResp[ev.id];
            if (!regSetDetails) return;

            const [device_status, action, disarm_reason] = this.pendingDataToStatus(regSetDetails);
            const label = ev.registration_set_display_label;

            return {
                registration_set_display_label: label,
                registration_set_id: ev.id,
                device_status,
                action,
                disarm_reason,
                ...regSetDetails
            } as UnderfrequencyPayload;
        }).filter(Boolean);
        return _.orderBy(joined, ['registration_set_id'], ['desc']);
    }

    // https://jira.springlab.enel.com/browse/BELIEBERS-654
    async optIn(payload: UnderfrequencyPayload) {
        await this.EMS.put(`/v1/registration_set/${payload.registration_set_id}/opt_in`, {}, {}).toPromise();
    }

    // https://jira.springlab.enel.com/browse/BELIEBERS-478
    async arm(payload: UnderfrequencyPayload): Promise<void> {
        console.log(payload);
        const locale = this.userService.user?.default_locale;

        // Fetch pretty much the entire hierarchy from EMS so we can send it back to EMS
        const [operatorResp, regResp, productResp, portfolioResp] = await Promise.allSettled([
            this.EMS.get(`/v1/operator_hierarchy`, {}).toPromise(),
            this.EMS.get(`/v1/registration/${payload.registration_id}`, {}).toPromise(),
            this.EMS.get(`/v1/product/${payload.product_id}`, {}).toPromise(),
            this.EMS.get(`/v1/portfolio?portfolio_id=${payload.portfolio_id}`, {}).toPromise(),
        ]);

        /*
         * Validate responses and try to be kind to our users
         */
        if (operatorResp.status === 'rejected') {
            throw new UnderfrequencyServiceError(`Could not fetch operator hierarchy`);
        }
        const operator = operatorResp.value.data.find((op) => (
            Boolean(op.programs.find(p => p.id === payload.program_id))
        ));
        if (!operator) {
            throw new UnderfrequencyServiceError(`Could not find operator for program id ${payload.program_id}`);
        }

        if (portfolioResp.status === 'rejected') {
            throw new UnderfrequencyServiceError(`Error fetching portfolio with id ${payload.portfolio_id}`);
        }
        const portfolio = (portfolioResp as PromiseFulfilledResult<any>).value.data;
        if (!portfolio) {
            throw new UnderfrequencyServiceError(`Could not find portfolio with id ${payload.portfolio_id}`);
        }

        if (regResp.status === 'rejected') {
            throw new UnderfrequencyServiceError(`Could not fetch registration with id ${payload.registration_id}`);
        }
        const registration = regResp.value;

        if (productResp.status === 'rejected') {
            throw new UnderfrequencyServiceError(`Could not fetch product with id ${payload.product_id}`);
        }
        const product = productResp.value.data;

        // We can only support certain types of obligation sources, and if its
        // not one of these, then we don't know what obligation to send to EMS.
        if (!ALLOWED_PORTFOLIO_OBLIGATION_SOURCES.includes(product.portfolio_obligation_source)) {
            const msg = `Cannot create event with portfolio obligation source ` +
                        `"${product.portfolio_obligation_source}". ` +
                        `Allowed sources are: ${ALLOWED_PORTFOLIO_OBLIGATION_SOURCES.join(', ')}`;
            throw new UnderfrequencyServiceError(msg);
        }

        const expCap = this.getExpectedCapacity(product, payload);

        // Assemble ye olde POST payload
        const emsPayload = {
            event_name_prefix: `emui-ufr-${Math.random().toString(36).substring(7)}`,
            event_start_dttm: new Date().toISOString(),
            event_end_dttm: null,
            event_type: 'OFFICIAL_UFR', // hardcoded
            obligation_uom: 'kW', // hardcoded
            portfolio: {
              // Portfolio attributes
              portfolio_id: portfolio.id,
              portfolio_display_label: payload.registration_set_display_label,
              obligation: payload.offer, // This gets overwritten by EMS, but we need to send a non-zero value
              selected_registration: {
                // Reg attributes
                id: registration.id,
                display_labels: registration.display_labels,
                preauthorized: registration.preauthorized,
                control_type: registration.control_type,
                flexible_asset_id: registration.flexible_asset_id,
                // Foreign key and display refs
                site_display_label: {
                    [locale]: payload.site_display_label,
                },
                site_id: payload.site_id,
                organization_name: {
                  [locale]: payload.organization_name,
                },
                organization_id: payload.organization_id,
                registration_set_id: payload.registration_set_id,
                registration_set_display_labels: {
                    [locale]: payload.registration_set_display_label
                },
                // Send the expected capacity as we understand it
                expected_capacity_uom: 'kW', // hardcoded
                expected_capacity_value: expCap,
                // We send availability as the expected capacity.
                availability_uom: 'kW', // hardcoded to DAD standard
                availability: payload.availability,
                // These are all set to '0'/false
                firm_service_level_uom: 'kW',
                firm_service_level_value: 0,
                registered_capacity_uom: 'kW',
                registered_capacity_value: 0,
                bonus_uom: 'kW',
                bonus_value: 0,
                is_fsl_indicator: false,
                is_generator_indicator: false,
                // Pass the core of the offer through to EMS so it can overwrite
                // the obligation value with it.
                criteria_objects: [
                    {
                        type: 'Customer Offer',
                        value: {offer_value: {kW: payload.offer}}
                    }
                ]
              }
            },
            // Foreign key and display refs
            operator_id: operator.id,
            operator_name: operator.display_labels, // Why is this "operator_name" but takes the object?
            product_id: payload.product_id,
            product_name: product.display_labels,
            program_id: payload.program_id,
            program_name: {
                [locale]: payload.program_display_label,
            },
        };
        await this.EMS.post('/v1/underfrequency_event/', emsPayload, {}).toPromise();
    }

    // https://jira.springlab.enel.com/browse/BELIEBERS-479
    async disarm(payload: UnderfrequencyPayload): Promise<void> {
        const qs = "on_hold=true&event_end_dttm="+ new Date().toISOString();

        await this.EMS.put(
            `/v1/event_node/${payload.event_node_id}/end_underfrequency_event?${qs}`,
            {},
            {}
        ).toPromise();
    }

    // https://jira.springlab.enel.com/browse/BELIEBERS-612
    async emergencyDisarm(payload: UnderfrequencyPayload): Promise<void> {
        const qs = "on_hold=true&opted_out=true&event_end_dttm="+ new Date().toISOString();

        await this.EMS.put(
            `/v1/event_node/${payload.event_node_id}/end_underfrequency_event?${qs}`,
            {},
            {}
        ).toPromise();
    }

    private async getRegistrationSetDetails(ids: string[]): Promise<{[k: string]: Partial<UnderfrequencyPayload>}> {
        if (ids.length === 0) return {};

        const response = await this.EMS.post(`/v1/registration_set_readings`, { ids }, {}).toPromise();
        return response.data.reduce((result, rsd) => {
            result[rsd.registration_set_id] = {
                registration_status: rsd.registration_status,
                registration_id: rsd.registration_id,
                site_id: rsd.site_id,
                organization_id: rsd.organization_id,
                portfolio_id: rsd.portfolio_id,
                program_id: rsd.program_id,
                product_id: rsd.product_id,
                program_display_label: rsd.program_display_label,
                portfolio_display_label: rsd.portfolio_display_label,
                site_display_label: rsd.site_display_label,
                organization_name: rsd.organization_name,
                product: {prez_conf: {prefered_prez_demand_uom: rsd.product_config_uom}},
                availability: rsd.availability?.kw || null,
                availability_zero_reason: rsd.availability?.zero_reason,
                availability_zero_reason_display_label: rsd.availability?.zero_reason_display_label,
                locked_out: Boolean(rsd.availability?.locked_out),
                offer: rsd.offer?.kw || null,
                offer_opt_out_state: Boolean(rsd.offer?.opt_out_state),
                load: rsd.demand?.kw || null,
                actual_frequency: rsd.actual_frequency ? rsd.actual_frequency.toFixed(2) + 'hz' : null,
                overlapping_event: rsd.overlapping_event,
            };
            return result;
        }, {});
    }

    // Active registration sets get their device status just from the workflow step
    // https://confluence.springlab.enel.com/display/EDP/Underfrequency+Management+Mappings
    private workflowStatusToDeviceStatus(node, regSetDetails): [DEVICE_STATUS, ACTION] {
        // If the registration set has a zero-d out availability across the flexible
        // asset, then flag it as DR-tripped.
        if ((regSetDetails?.availability_zero_reason || []).includes('otherwise_active')) {
            return [DEVICE_STATUS.DR_TRIPPED, null];
        }

        // Otherwise use the workflow status to show where we are in the event
        switch (node?.workflow_status) {
            case 'READY':
                return [DEVICE_STATUS.ARMING, null];
            case 'CURTAILING':
                return [DEVICE_STATUS.ARMING, ACTION.DISARM];
            case 'CURTAIL_COMPLETE':
                return [DEVICE_STATUS.ARMED, ACTION.DISARM];
            case 'CURTAIL_EXCEPTION_DETECTED':
                return [DEVICE_STATUS.ARM_ERROR, ACTION.MANAGE_EXCEPTION];
            case 'MANUALLY_TRIPPING':
                return [DEVICE_STATUS.TRIPPING, ACTION.EMERGENCY_DISARM];
            case 'TRIP_EXCEPTION_DETECTED':
                return [DEVICE_STATUS.TRIP_ERROR, ACTION.MANAGE_EXCEPTION];
            case 'TRIP_COMPLETE':
            case 'TRIPPED_NOTIFICATIONS_SENT':
                return [DEVICE_STATUS.TRIPPED, ACTION.EMERGENCY_DISARM];
            case 'RESTORE_TRIP_NOTIFICATIONS_SENT':
                return [DEVICE_STATUS.TRIPPED, null];
            case 'RESTORE_COMPLETE':
                return [DEVICE_STATUS.DISARMED, null];
            case 'RESTORE_EXCEPTION_DETECTED':
                return [DEVICE_STATUS.DISARM_ERROR, ACTION.MANAGE_EXCEPTION];
            case 'RESTORING_AND_DISARMING':
            case 'RESTORING':
                return [DEVICE_STATUS.DISARMING, null];
            default:
                return [DEVICE_STATUS.UNKNOWN, null];
        }
    }

    private pendingDataToStatus(regSetDetails) {
        if (regSetDetails?.overlapping_event) {
          return [DEVICE_STATUS.DISARMED, null, DISARM_REASON.DR_TRIPPED];
        }
        if ((regSetDetails?.availability_zero_reason || []).includes('otherwise_active')) {
            // Get what the disarm reason would otherwise be
            const [status, action, disarmReason] = this.pendingDataToStatus({
                ...regSetDetails,
                availability_zero_reason: []
            });
            const newAction = disarmReason === DISARM_REASON.MANUAL_DISARM ?
                             ACTION.GREYED_OPT_IN :
                             ACTION.GREYED_ARM;
            return [DEVICE_STATUS.DR_TRIPPED, newAction, disarmReason];
        }
        if (regSetDetails.locked_out) {
          return [DEVICE_STATUS.LOCKED_OUT, null, DISARM_REASON.LOCAL_LOCKOUT];
        }
        if (regSetDetails.registration_status === 'ON_HOLD') {
            return [DEVICE_STATUS.DISARMED, ACTION.OPT_IN, DISARM_REASON.MANUAL_DISARM];
        }
        if (!regSetDetails?.offer) {
            return [DEVICE_STATUS.DISARMED, null, DISARM_REASON.NO_CUSTOMER_OFFER];
        }
        if (regSetDetails?.offer_opt_out_state) {
            return [DEVICE_STATUS.DISARMED, null, DISARM_REASON.CUSTOMER_OPT_OUT];
        }
        if (regSetDetails?.availability_zero_reason_display_label) {
            return [DEVICE_STATUS.DISARMED, null, regSetDetails.availability_zero_reason_display_label];
        }
        if (!regSetDetails?.availability) {
            return [DEVICE_STATUS.DISARMED, null, DISARM_REASON.NO_AVAILABILITY];
        }
        return [DEVICE_STATUS.DISARMED, ACTION.ARM, DISARM_REASON.READY_TO_ARM];
    }

    private getExpectedCapacity(product: any, payload: UnderfrequencyPayload) {
        switch (product.expected_capacity_source) {
            case 'AVAILABILITY':
                return payload.availability;
            case 'CLEARED_OFFER':
            case 'CUSTOMER_OFFER':
                return payload.offer;
            default:
                throw new UnderfrequencyServiceError(`Cannot set expected capacity from source "${product.expected_capacity_source}"`);
        }
    }
}
